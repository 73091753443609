import React from 'react'

const Pillar = ({ title, children, message, messageAuthor }) => (
  <div className="card" style={{ backgroundColor: '#ffffff7d' }}>
    <div className="card-image">
      <figure className="image">
        {children}
      </figure>
    </div>
    <div className="card-content">
      <div className="media">
        <div className="media-left">
          <figure className="image is-48x48">
            {children}
          </figure>
        </div>
        <div className="media-content">
          <p className="title is-4">{title}</p>
          <p className="subtitle is-6">@{messageAuthor}</p>
        </div>
      </div>

      <div className="content">
        {message}.
        {/*  <a href="#">#css</a> <a href="#">#responsive</a>
        <br/>
        <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>*/}
      </div>
    </div>
  </div>
)

export default Pillar