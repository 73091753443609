import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundPillarsSection = ({ className, children,classId }) => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "pillars-6.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
               render={data => {
                 // Set ImageData.
                 const imageData = data.desktop.childImageSharp.fluid
                 return (
                   <BackgroundImage Tag="section"
                                    className={className}
                                    classId={classId}
                                    fluid={imageData}
                                    backgroundColor={`#fff`}
                   >
                     {children}
                   </BackgroundImage>
                 )
               }
               }
  />
)

export default BackgroundPillarsSection