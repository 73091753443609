import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundSection from './background-section'
import './layout-home.scss'
import AsafeerDelMondoLogo from '../assets/adm-logo.svg'
import { Link } from 'gatsby'

const LayoutHome = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleHomeQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <BackgroundSection className="hero is-fullheight" classId="hero">
          <div className="hero-head has-background-white-transparent">
            <div className="container">
              <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                  <Link className="navbar-item" to="/">
                    <AsafeerDelMondoLogo/>
                  </Link>
                </div>
                <div className="navbar-menu">
                </div>
              </nav>
            </div>
          </div>
          <div className="hero-body hr hr--bottom has-background-white-transparent-2">
            <div className="container">
              <div className="columns">
                <div className="column is-three-quarters ">
                  <h1 className="hero-title title is-size-1 is-size-3-mobile">
                    Exploring the synergies in the arts from auditory, visual and physical
                    to empower, change, and create an integrated, immersed world.
                  </h1>
                  <a id="open-popup" className="button is-primary hero-action is-large">
                    <strong>Stay in touch</strong>
                  </a>
                  {/*<a  href="mailto:info@asafeerdelmondo.com?Subject=Hello"*/}
                     {/*className="button is-primary hero-action is-large">Get Involved</a>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="hero-foot"/>
        </BackgroundSection>
        <div>
          {children}
        </div>
        <footer className="footer">
          <div className="content has-text-centered">
            {data.site.siteMetadata.title} © {new Date().getFullYear()}
          </div>
        </footer>
      </>
    )}
  />
)

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutHome
