import React from 'react'

import LayoutHome from '../components/layout-home'
import SEO from '../components/seo'
import './index.scss'
import BackgroundPillarsSection from '../components/background-pillars-section'
import Pillar from '../components/pillar'
import AuditoryImage from '../components/auditory-image'
import PhysicalImage from '../components/physical-image'
import VisualImage from '../components/visual-image'

const IndexPage = () => (
  <LayoutHome>
    <SEO title="Home" keywords={[`asafeerdelmondo`, `design`, `agency`]}/>
    <BackgroundPillarsSection className="section" classId="pillars">
      <div className="container ">
        <div className="columns is-centered">
          <div className="column">
            <h1 className="title">
              <span className="mission-border">
              Mission
              </span></h1>
            <div className="is-size-4 mission-text">
              <strong>Asafeer Del Mondo</strong> aims to
              develop systems that create habits and allow for adopting small pieces of auditory, visual and physical
              activities that will alleviate our thoughts and body. Incorporating fragments of those three pillars in
              our
              daily lives
              to bridge the gap from passive experiences to an integrated, immersive life full of love, laughter, and
              happiness.
            </div>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column">
            <Pillar title="Auditory"
                    messageAuthor="Plato"
                    message="Music gives a soul to the universe, wings to the mind, flight to the imagination and life to everything">
              <AuditoryImage/>
            </Pillar>
          </div>
          <div className="column">
            <Pillar title="Physical"
                    messageAuthor="Mahatma Gandhi"
                    message="Strength does not come from physical capacity. It comes from an indomitable will">
              <PhysicalImage/>
            </Pillar>
          </div>
          <div className="column">
            <Pillar title="Visual"
                    messageAuthor="Jerzy Kosinski"
                    message="The principles of true art is not to portray, but to evoke">
              <VisualImage/>
            </Pillar>
          </div>
        </div>
      </div>
    </BackgroundPillarsSection>
    <section className="section hr hr--top">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-size-4"> More coming soon, stay tuned!</div>
        </div>
      </div>
    </section>
  </LayoutHome>
)

export default IndexPage
